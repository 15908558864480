import ng from 'angular';

// /*
//   acpNumberToWords Filter. This filter is used to convert a number, 23, to the respecitve
//   english phrase; twenty three. This implemantation is limited in its inputs:

//   Limitations / Issues:
//     - Does not handle negatives. -23 prints 'undefined twenty three'
//     - Does not handle decimal numbers. It returns the input number if decimals are detected.
//     - Does not insert commas / conjunctions to separate phrases. 1234 prints 'one thousand
//       two hundred thirty four' as opposed to 'one thousand, two hundred and thirty four'
//  */

function grp(iNumber: string) {
  return ('000' + iNumber).substr(-3);
}

function rem(iNumber: string) {
  return iNumber.substr(0, iNumber.length - 3);
}

function numberToWords(iNumber: string) {
  if (parseInt(iNumber, 10) === 0) return 'zero';

  let text = '';
  const a = [
    '',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen'
  ];
  const b = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety'
  ];
  const g = [
    '',
    'thousand',
    'million',
    'billion',
    'trillion',
    'quadrillion',
    'quintillion',
    'sextillion',
    'septillion',
    'octillion',
    'nonillion'
  ];

  function fmt(_ref: string) {
    const h = _ref[0];
    const t = _ref[1];
    const o = _ref[2];

    return [
      Number(h) === 0 ? '' : a[h] + ' hundred ',
      Number(o) === 0 ? b[t] : (b[t] && b[t] + ' ') || '',
      a[t + o] || a[o]
    ].join('');
  }

  function cons(xs: string) {
    return function(x: string) {
      return function(g1: string) {
        return x ? [x, (g1 && ' ' + g1) || '', ' ', xs].join('') : xs;
      };
    };
  }

  function iter(str: string) {
    return function(i: number) {
      return function(x: string) {
        return function(r: string) {
          if (x === '000' && r.length === 0) return str;
          return iter(cons(str)(fmt(x))(g[i]))(i + 1)(grp(r))(rem(r));
        };
      };
    };
  }

  text += iter('')(0)(grp(String(iNumber)))(rem(String(iNumber)));

  return text;
}

export function acpNumberToWordsFilter() {
  return numberToWords;
}

export default ng
  .module('acp.shared.filters.numberToWords', [])
  .filter('acpNumberToWords', acpNumberToWordsFilter);
